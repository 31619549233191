import React, { useEffect } from "react"

import { withPreview } from "gatsby-source-prismic"
import { graphql } from "gatsby"

import {
  isBrowser,
  LoadingIndicator,
  renderSEO,
  PublicRoute,
  ROUTES,
  SEGMENT_SCRIPT,
  DATA_LAYER_SCRIPT,
  pageViewTracking,
  LOGIN_STATUS,
} from "@lesmills/gatsby-theme-common"
import { sessionReceiverURL } from "../../../../gatsby-theme-common/src/services/amplifyStorage"

const SignIn = React.lazy(() => import("../../components/SignIn"))

type Props = {|
  location: Object,
  pageContext: {
    lang: string,
  },
  data: Object,
|}

const SignInPage = (props: Props) => {
  const { pageContext, data } = props || {}
  const lang = pageContext.unPublishedLang || pageContext.lang
  if (!data) return null

  const signInData = data.prismicSignInPage.data
  const layoutData = (data.prismicLayout && data.prismicLayout.data) || {}

  const scriptsSignInPage = [SEGMENT_SCRIPT, DATA_LAYER_SCRIPT]

  useEffect(() => {
    pageViewTracking(undefined, LOGIN_STATUS.LOGGED_OUT)
  }, [])

  const renderSignInPage = signInProps => {
    return (
      <>
        {renderSEO(signInData, ROUTES(lang).SIGN_IN, lang, scriptsSignInPage)}
        {isBrowser && (
          <React.Suspense fallback={<LoadingIndicator />}>
            <SignIn
              data={signInData}
              layoutData={layoutData}
              location={props.location}
              lang={lang}
              loaded={!signInProps.checkingSession}
            />
          </React.Suspense>
        )}

        {process.env.GATSBY_RT_26_07_2022_SYNC_SESSION === "true" &&
          sessionReceiverURL && (
            <iframe
              title="session_receiver"
              id="session_receiver"
              src={sessionReceiverURL}
              width="0"
              height="0"
            />
          )}
      </>
    )
  }

  return <PublicRoute component={renderSignInPage} {...props} lang={lang} />
}

export const query = graphql`
  query SignInPage($lang: String) {
    prismicSignInPage(lang: { eq: $lang }) {
      data {
        title {
          text
          html
        }
        page_title {
          text
        }
        create_account {
          text
        }
        email_label {
          text
        }
        forgot_password {
          text
        }
        invalid_email {
          text
        }
        invalid_password {
          text
        }
        password_label {
          text
        }
        remember_me {
          text
        }
        required_email {
          text
        }
        required_password {
          text
        }
        sign_in_button {
          text
        }
        title {
          text
        }
        reset_password_success_title {
          text
        }
        reset_password_success_subtitle {
          text
        }
        page_title {
          text
        }
        page_description {
          text
        }
        page_keywords {
          text
        }
        page_author {
          text
        }
        page_og_type {
          text
        }
        page_og_title {
          text
        }
        page_og_description {
          text
        }
        page_twitter_title {
          text
        }
        page_twitter_description {
          text
        }
        page_twitter_card {
          text
        }
        user_not_found_exception {
          text
        }
        not_authorized_exception {
          text
        }
        network_error {
          text
        }
        page_image {
          alt
          url
        }
      }
    }
    prismicLayout(lang: { eq: $lang }) {
      data {
        lmod_uace_err {
          text
        }
        les_mills_plus_logo_black {
          alt
          url
        }
      }
    }
  }
`

export default withPreview(SignInPage)
